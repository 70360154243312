.Form-position-center {
    position: fixed;
    top: 20px;
    right: 20px;
    bottom: 0;
    pointer-events: none;
    z-index: 1000000;
    display: flex;
}

.Form-position-Form-login {
    overflow: hidden;
}

.Form {
    pointer-events: all;
    position: relative;
    padding: 0px;
    bottom: 0;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    width: 600px;
    border-radius: 5px 5px 0 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    /* animation slide left set */

    animation: slideLeft 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

}

.Hierarchy-title {
    padding: 10px;
}

.Form .imagination {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 2px 5px #aaa;
    display: block;
    height: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin-bottom: 20px;
}

.Form .Header {
    margin-bottom: 0;
    z-index: 10000;
    background: rgb(73, 118, 255);
    color: #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    width: auto;
}

.Form .Header i {
    color: #fff;
}

.Form form {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.Form-content {
    padding: 30px 50px 50px;
    margin-top: 0px;
    margin: 0px 0px 20px 0px;
    margin-bottom: 0px;
    overflow-y: scroll;
    flex: 1;
    overflow-x: hidden;
    flex-direction: column;
}

.Form-error {
    background: rgb(255, 243, 243);
    padding: 20px 40px;
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -20px;
    border-bottom: 1px solid rgba(255, 0, 0, 0.38);
}

.Form-error-message {
    animation-duration: 1s;

    animation-fill-mode: both;
    animation-name: FormShow;
    animation-timing-function: cubic-bezier(.17, .67, .04, .99)
}

.Form-error-title {
    font-weight: bold;
    font-size: 20px;
    color: red;
}

.Form-error-message i {
    position: absolute;
    left: 0;
    width: 30px;
    top: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    display: block;
}

.Form-error-message {
    position: relative;
    line-height: 40px;
    padding-left: 35px;
    font-weight: 300;
    font-size: 13px;
    border-radius: 5px;
    margin-top: 0px;
    color: red;
}

.Form-login {
    animation: none;

    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    padding: 10px 30px;
    border-bottom: 1px solid #eee;
    border-right: none;
    box-sizing: border-box;
    right: auto;
    width: 350px;
}

.Form-login form {
    overflow: inherit !important;
}

.Form-login .Form-content {
    padding: 0;
    overflow: inherit;
}

/* animation slide left */
@keyframes slideLeft {
    0% {
        transform: translateY(400px);
    }

    100% {
        transform: translateY(0px);
    }
}

.Form input,
.Form textarea,

.Form .Icons {
    line-height: 40px;
    width: 100%;
    padding: 0;
    overflow: hidden;

    margin: 0;
    background: #fff;
    font-size: 15px;
    text-indent: 15px;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid #ddd
}

.Form textarea {
    resize: vertical;
    height: 200px;
    overflow-y: scroll;
    padding: 20px;
    text-indent: 0;
    line-height: 1.4;
    font-family: Arial, Helvetica, sans-serif;
}

.Form p {
    font-weight: bold;
    font-size: 15px;
    opacity: 1;
    position: relative;
    color: rgb(26, 38, 73);
    margin: 20px 0px 10px 0px;
}

.Form p.description {
    font-weight: normal;
    margin-top: -5px;
    font-size: 12px;
}

.Form p.description+.checkbox {
    margin-top: -10px;
}

.Form p::befxore {
    content: "";
    display: block;
    width: 30px;
    position: absolute;
    left: -40px;
    top: 7px;
    height: 3px;
    background: rgba(0, 0, 0, 0.096);
}

.Form input:focus {
    border-color: rgb(73, 73, 255);
    border-style: solid;
    outline: 4px solid rgb(234, 234, 234);
}

/* animation fade opacity */
@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.4;
    }
}

.Form-background {
    position: fixed;
    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.2);
    bottom: 0;
    right: 0;
    z-index: 100;
    /* animation fade set */
    /* animation: fade 1s cubic-bezier(0.075, 0.82, 0.165, 1); */

}

.Form-Popup button[type='submit'] {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    width: 550px;
    padding: 10px 0;
}

.Form button[type='submit'] i {
    font-size: 20px;
    color: rgb(73, 118, 255);

    margin-left: 10px;
}





.Form button[type='submit'] {
    background: rgb(255, 255, 255) !important;
    padding: 10px 20px;
    margin: 0px 0;
    font-weight: normal;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 15px;
    border-radius: 5px;

    width: auto;
    letter-spacing: 3px;
    color: rgb(73, 118, 255);

    font-weight: bold;
}

.downloadFile {
    border-radius: 5px;
    text-decoration: none;
    color: #000;
    display: block;
    color: #fff;
    background: rgb(73, 118, 255);
    padding: 10px 10px;
    margin-bottom: 10px;
}

.Form button[type='submit']:hover {
    background: rgb(73, 118, 255) !important;
    color: #fff;
    cursor: pointer;
}

.Form-login button[type='submit'] {
    line-height: 20px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 5px 10px;
    background: rgba(73, 73, 255, 0) !important;
    color: rgb(73, 118, 255);
}

.Form-login button[type='submit']:focus {}

.Form-error-header {
    margin-left: -50px;
    margin-right: -50px;
    line-height: 1.5;
    font-size: 12px;
    animation-duration: 1s;

    animation-fill-mode: both;
    animation-name: FormShowVertical;
    animation-timing-function: cubic-bezier(.17, .67, .04, .99);
    margin-top: -30px;
}

.Form-login .Form-error-header {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -20px;
}

.Form-warning {
    margin-left: -30px;
    margin-right: -30px;
}

.Form-warning {
    display: flex;
    background: rgb(255, 255, 255);

    border-bottom: 1px solid #ddd;
}

.Form-warning-title {
    background: rgba(0, 0, 0, 0.051);
    display: inline-block;
    line-height: 26px;
    margin: 7px;
    font-weight: bold;
    color: rgba(255, 0, 0, 0.51);
    border-radius: 3px;
    font-size: 11px;
    padding: 0 15px;
}

.Form-warning-message {
    display: inline-block;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    padding-left: 7px;
    line-height: 40px;
    color: #000;
}

.Loading {
    width: 100%;
    height: 2px;
    position: relative;
    display: flex;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.6, 1.0);
}

.Loading:after {
    content: "";
    display: block;
    width: 40%;
    height: 100%;
    opacity: 0;
    background-color: rgb(73, 73, 255);
    position: absolute;
    animation: load-infinite-a 1s infinite;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.6, 1.0);
}

.Loading::before {
    content: "";
    display: block;
    width: 60%;
    height: 100%;
    background-color: rgb(73, 73, 255);
    position: absolute;
    animation: load-infinite-b 1s infinite;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.6, 1.0);
}



@keyframes load-infinite-a {
    0% {
        opacity: 1;
        transform: translate(-100%, 0px);
    }

    75% {
        opacity: 1;
        transform: translate(300%, 0px);
    }

    75.01% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        /* temp hold value */
        transform: translate(300%, 0px);
        /* temp hold value */
    }
}

@keyframes load-infinite-b {
    0% {
        opacity: 0;
        /* temp hold value */
        transform: translate(-150%, 0px);
        /* temp hold value */
    }

    24.99% {
        opacity: 0;
    }

    25% {
        opacity: 1;
        transform: translate(-150%, 0px);
    }

    100% {
        transform: translate(166.66%, 0px);
        opacity: 1;
    }
}

.Form-divisor {
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.171);
    background: rgba(0, 0, 0, 0.05);
    margin-left: -50px;
    margin-right: -50px;
    margin-top: 30px;
    padding-left: 50px;
}


.Form-buttons {
    gap: 20px;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 20px;
    flex-direction: row;
    flex-direction: row-reverse;
}

.Form-loading .Form-buttons {
    opacity: 0.2;
    pointer-events: none;
}



.Form-login .Form-buttons {
    padding: 20px 0;
    box-shadow: none;
    background: none !important;
}

.Form-secondary {
    font-size: 13px;
    margin-right: auto;
    text-transform: uppercase;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.Form-secondary i {
    margin-right: 10px;
    font-size: 20px;
}

.Form-error-header {
    padding: 30px 30px 40px 30px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: rgb(255, 247, 247);
    border-bottom: 1px solid rgb(223, 223, 223);
}

.Form-error-header div {
    border-left: 4px solid rgb(238, 129, 129);
    text-align: left;
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px;
}

.Form-delete-info {
    margin-top: 20px;
}

.Form-delete-info-title {
    font-size: 20px;
    font-weight: bold;
}

.Form-delete-info-description {
    font-size: 16px;
    margin-top: 10px;
    line-height: 1.4;
    opacity: 0.5;
    font-weight: normal;
}

.Form-error-header i {
    position: absolute;
    left: -50px;
    font-size: 200px;
    display: none;
    top: -70px;
    opacity: 0.1;
}

.Form-error-header b {
    font-size: 11px;
    padding: 7px 20px;
    border-radius: 3px 3px 0px 0px;
    clear: both;
    text-align: center;
    float: left;
    border: 1px solid #ddd;
    position: absolute;
    left: 30%;
    z-index: 10000;
    bottom: 0;
    border-bottom: none;
    line-height: 1;
    right: 30%;
    display: block;
    background: rgba(255, 255, 255, 0.651);

}

.Form-error-header:hover b {
    color: red;

}


.Form-success {
    position: relative;
    background: rgb(232, 255, 232);
    border-bottom: 1px solid #ddd;
    padding: 20px;
    margin-left: -50px;
    margin-bottom: 40px;
    margin-right: -50px;
    margin-top: -30px;
    padding-left: 50px;

}

.Form-success i {
    position: absolute;
    left: 20px;
    top: 20px;
}


/* checkbox input */
.Form input[type="checkbox"] {
    height: 29px;
    width: 54px;
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    outline: none !important;
    margin-top: -15px;
    border-radius: 100px;
    float: right;
    overflow: visible;
    padding: 2px;
    appearance: none;
    background: #e6e6e8;
}

.Form input[type="checkbox"]::after {
    height: 25px;
    width: 25px;
    content: "";
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    display: block;
    background: rgb(255, 255, 255);
    border-radius: 100px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.274) inset
}

.Form .checkbox {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    position: relative;
}

.Form input[type="checkbox"]:checked:after {
    margin-left: 25px;
}

.Form input[type="checkbox"]:checked {
    background: rgb(73, 118, 255);

}


@keyframes FormShowVertical {
    from {
        transform: translate3d(0%, -20%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes FormShow {
    from {
        transform: translate3d(-10%, -20%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.finder-drag {
    border-radius: 5px;
    border: 2px dashed #aaa;
    line-height: 100px;
    text-align: center;
    font-size: 20px;
    background: rgb(245, 246, 249);
    position: relative;
}

.finder-drag p {
    font-size: 16px;
    font-weight: 400;
}

.finder-drag i {
    font-size: 100px;
    line-height: 130px;
    top: 0;
    left: 0;
    opacity: 0.1;
    right: 0;
    position: absolute;
}

.finder-progress {
    height: 30px;
    width: 100%;
    display: none;
    border-radius: 6px;
    overflow: hidden;
    margin: 10px 0;
    background: #eee;
}

.finder-progress-bar {
    height: 30px;
    width: 0px;
    border-radius: 6px;

    background: rgb(73, 118, 255);

}

.finder-loading .finder-progress {
    display: block;
}

.finder-complete .finder-success {
    display: block;
}

.finder-progress-bar {
    -webkit-transition: all 1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    -moz-transition: all 1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    -o-transition: all 1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    transition: all 1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.finder-file {
    font-size: 15px;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 0 0 0 25px;
    line-height: 50px;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.finder-file:hover {
    background: rgb(73, 118, 255);
    color: #fff;
}

.finder-file:hover i {
    color: #fff;
}

.finder-file i {
    float: right;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.03));
    color: #fff;
    width: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.4);
    margin: 0px;
    border-left: 1px solid rgba(170, 170, 170, 0.2);
}

.finder-success {
    display: none;
    background: rgb(231, 255, 231);
    font-size: 15px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 0 0 0 25px;
    line-height: 50px;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}


.finder-error {
    display: none;
    background: rgb(255, 246, 246);
    font-size: 13px;
    border-radius: 6px;
    overflow: hidden;
    color: rgb(195, 101, 101);
    margin-bottom: 20px;
    padding: 0 0 0 15px;
    line-height: 30px;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.finder-success i {
    color: rgb(0, 0, 0);
    margin-right: 20px;
}


.finder-error {
    display: none;
}

.finder-failed .finder-error {
    display: block;
}

.finder-error i {
    margin-right: 15px;
}


.Form-disabled {
    position: absolute;
    bottom: 0;
    padding: 13px 14px;
    font-size: 17px;
    right: -40px;
    color: rgb(242, 139, 139);
}

.Form-box-tab .Form-disabled {
    right: -38px !important;
}

.Form-box-checkbox .Form-disabled {
    bottom: 3px !important;
}

.Form-disabled-cover {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
}


.Form-box,
.Form-box-text {
    position: relative;
}