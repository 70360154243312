.calendarWrapper {
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative
}

.calendarHeader {
    background: #222;
    padding: 50px;

}

.calendarHeader h1 {
    color: #fff;
}

.thickText {
    font-weight: 700;
}

.thinText {
    font-weight: 200;
}

button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-size: 0.8em;
    font-weight: 700;
}

.calendarHeaderButtons {
    color: #d0661c;
    overflow-y: hidden;
    margin: 20px 0;
    font-size: 30px;
}

.calendarHeaderButtons button:nth-of-type(1) {
    float: left;
    padding-left: 0;
    margin-left: 0;
}

.calendarHeaderButtons button:nth-of-type(2) {
    float: right;
}

.modeWrapper {
    text-align: center;
    background: #000;
    padding: 30px 0 0 0;
    color: #fff;
}

.modeButton {
    padding: 10px 20px;
    color: #fff;
    transition-duration: 0.3s;
}

.modeButton:hover {
    color: #ff3e52;
}

.modeButtonActive {
    color: #ff3e52;
}

.yearlyCalendar {
    text-align: center;
}

.yearlyMonthWrapper {
    padding: 20px;
    overflow-y: hidden;
}

.yearlyMonth {
    background: #fff;
    display: inline-block;
    float: left;
    height: 300px;
    border-radius: 5px;
    margin: 20px;
    box-sizing: border-box;
    width: calc(100%/ 3 - 40px);

}

.yearlyMonth .yearlyMonthName {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.yearlyMonth .weekRow {
    height: 30px;
}

.yearlyMonth .calendarDayOfWeek div {
    text-align: center;
    opacity: 0.5;
    margin-bottom: 10px;
}


.yearlyMonth .inactiveDay .currentDay {
    color: #2f3ec154;
    display: unset;
    border-radius: unset;
    background-color: transparent;
}


.yearlyMonth .dayCell {
    height: 30px;
    text-align: center;



}

.yearlyMonth .dayCell .dayText {
    padding: 20px;
    display: block;
}

.yearlyMonth .dayCell .dayDate.currentDay {
    width: 50%;
    height: 50%;
}

.dayCell .dayDate {
    font-size: 0.8em;
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

}



.calendarWrapper {
    width: 100%;
    color: #202029;
}

@media only screen and (max-width: 426px) {
    .yearlyMonth {
        width: calc(100% / 2);
    }
}


.dayCell {
    display: flex;
    box-sizing: border-box;
    position: relative;
    text-align: right;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    height: 160px;
    background: rgb(56, 56, 56);

}

.inactiveDay {
    background: rgba(0, 0, 0, 0.5) !important;
}

.inactiveDay .dayText {
    color: #fff;

}

.dayCell .dayDate {
    display: block;
    text-align: right;
    background: #fff;
    padding: 3px;
    position: relative;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.dayCell .dayDate:hover {
    background: #e9e9e9;
}

.dayCell .inactiveDay {
    color: #b5b5b5;
}

.dayText {
    display: inline-block;
    position: relative;
    font-size: 15px;
    padding: 20px;
}

.currentDay {
    border-radius: 50%;
    color: white;
    background-color: #ff3e52;
}

.dayCellEventWrapper {
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 0;
    right: 0;
}

.dayEventsRemaining {
    position: absolute;
    font-size: 14px;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    padding: 5px 8px;
    margin: 10px;
    background: #eee;
}

.weekRow {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;

}


.calendarDayOfWeek {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    font-weight: 300;
    background: #555;
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
}

.calendarDayOfWeek div {
    box-sizing: border-box;

    text-align: center;
    padding: 0 5px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.445);
    line-height: 50px;
    flex: 1;
    display: inline-block;
    color: #fff;
}

.calendarDayOfWeek div:last-child {
    border-right: none;
}

.dayCellEvent {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    display: block;
    text-align: left;
    width: 100%;
    text-overflow: ellipsis;
    border-radius: 8px;
    margin-bottom: 3px;
    margin-left: 20px;
}

.dayCellEvent:nth-last-of-type(0) {
    margin-bottom: 0;
}

.eventTitle {
    padding: 0 5px;
    color: white;
    font-size: 0.6em;
    line-height: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}

.eventStart {
    margin-left: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.eventMiddle {
    border-radius: 0;
    margin-left: 0;
}

.eventEnd {
    margin-left: 0;
    margin-right: 20px;
    width: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.eventInactive {
    opacity: 0.5;
}


.dailyWrapper {
    display: flex;
    flex: 1;
}

.dailyHourTextWrapper {
    position: absolute;
}

.dailyTimeLine {
    position: absolute;
    width: calc(100% - 80px);
    margin-left: 80px;
}

.dailyTimeLineWrapper {
    position: relative;
    flex: 1;
    overflow-y: scroll;
}

.dailyHourWrapper {
    height: 40px;
}

.dailyHour {
    position: relative;
    height: 20px;
}

.dailyHourText {
    height: 100%;
    padding-left: 20px;
    line-height: 20px;
    width: 80px;
    display: inline-block;
    font-size: 0.8em;
    font-weight: 600;
    color: #111;
}

.dailyHourLine {
    height: 1px;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    background-color: #b5b5b5;
}

.dayEvent {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    z-index: 10;
}

.dayEventMultiple {
    padding-right: 20px;
}

.allDayEvent {
    min-height: 20px;
    box-sizing: border-box;
    margin: 10px 0;
}


.dailyEventWrapper {
    font-size: 0.8em;
    min-height: 1.6em;
    left: 50px;
    height: 100%;
    color: white;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px 10px;
    line-height: 100%;
}

.dailyEventTitle {
    font-weight: 600;
}