.Tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    gap: 15px;
}

.Tabs a {
    padding: 0 10px 0 0;
    line-height: 40px;
    background: rgba(0, 0, 0, 0.082);
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: flex;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;

}

.Tabs a:hover {
    background: rgb(73, 73, 255) !important;
    color: #fff;
}

.Tabs a i {
    padding: 0 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.082);
    font-size: 18px;
    opacity: 0.7;
    line-height: 40px;
    margin-right: 14px;
}

.Tabs a.active i {
    background: rgba(0, 0, 0, 0.2);
}

.Tabs a.active {
    background: rgb(73, 73, 255) !important;
    color: #fff;
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}

@-webkit-keyframes bounceIn {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounceIn {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}