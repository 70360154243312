.Document {
    background: red;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1000000;
    bottom: 0;
}

.button-close {
    position: fixed;
    z-index: 1000;
    background: rgb(77, 77, 77);
    color: rgb(255, 255, 255);
    height: 50px;
    width: 50px;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    right: 20px;
    top: 20px;
    border-radius: 100px;
    font-size: 25px;
    display: block;
}

.button-print {
    position: fixed;
    z-index: 1000;
    background: rgb(27, 38, 118);
    color: rgb(255, 255, 255);
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    right: 80px;
    top: 20px;
    cursor: pointer;
    border-radius: 100px;
    font-size: 25px;
    display: block;
}

.Document iframe {
    margin: 0 auto;
    flex: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    ;
}