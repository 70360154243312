.Details ul {
    padding: 0;
    margin: 0;
}

.Details li {
    display: flex;
    line-height: 40px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.178);
}

.Details li:nth-child(2n) {
    background: #f5f5f5;
}


.Details li i {
    width: 50px;
    text-align: center;
    font-size: 20px;
}

.Details li span {
    width: 100px;
    margin-left: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.229);
    background: rgba(0, 0, 0, 0.05);
    padding: 5px 20px;
}