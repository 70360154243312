.Drager {

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    background-color: rgb(130, 130, 130);
    display: flex;
    .column:first-child>div {
        background: transparent !important;
    }

    .Drager-columns {
        display: flex;
        flex: 1;
        padding: 0px;

        .Drager-column {
            flex: 1;
            display: flex;
            flex-direction: column;



            border-right: 1px solid #aaa;

            .Drager-column-title {
                font-size: 12px;
                border-top: 5px solid #aaa;
                padding: 20px 20px 0;
                background-color: #fff;
            }

            .Drager-column-description {
                font-size: 12px;background-color: #fff;
                padding: 5px 20px 20px 20px;
                white-space: nowrap;
                border-bottom: 1px solid #ddd;
                color: rgba(0, 0, 0, 0.5);
            }

            .Drager-column-content {
                overflow-y: scroll;
                max-height: 800px;
                padding: 0px 16px 0;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                display: flex;
            }

            .Drager-column-item {
                background: rgb(255, 255, 255);
                box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                margin-bottom: 20px;
                max-width: 300px;
                width: 300px;
                min-width: 300px;
                position: relative;
                &:first-child{
                    margin-top: 17px;
                }
                &:hover {
                    background-color: rgb(255, 250, 223);
                }

                .Drager-column-item-header {
                    background: rgb(255, 255, 255);
                    font-size: 14px;
                    flex: 1;position: sticky;
                    top: 0;
                    gap: 0;
                    display: flex;
                    font-weight: bold;
                    align-items: center;
                    border-bottom: 1px solid #ccc;
                    border-radius: 5px 5px 0 0;
                    color: rgba(0, 0, 0, 0.8);
                    padding: 14px 10px 14px 14px;

                    b {
                        background: #eee;
                        padding: 2px 6px;
                        font-weight: 400;
                        border-radius: 2px;
                        margin-right: 6px;
                        border: 1px solid rgba(170, 170, 170, 0.347)
                    }

                    span {
                        color: rgb(0, 0, 0);
                        font-weight: bold;
                        margin-right: 40px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        flex: 1;
                    }

                    i {
                        position: absolute;
                        top: 4px;
                        right: 0;
                        margin-left: auto;
                        font-size: 20px;
                        line-height: 42px;
                        text-align: center;
                        width: 40px;
                        color: rgba(0, 0, 0, 0.353);
                    }
                }

                .Drager-column-item-title {
                    color: #000;
                    font-weight: bold;
                    display: flex;
                    background-color: rgb(234, 234, 234);
                    align-items: center;
                    font-size: 14px;
                    padding: 10px 0px 10px 12px;
             
                    i {
                        height: 30px;
                        width: 30px;
                        margin-right: 10px;
                        background: rgb(98, 123, 202);
                        border-radius: 100px;
                        color: #fff;
                        line-height: 30px;
                        text-align: center;
                        font-size: 20px;
                        margin-right: 10px;
                    }

                    border-bottom: 1px solid #ccc;
                }

                .countdown-wrapper {
                    position: absolute;
                    right: 2px;
                    border-radius: 40px 40px 0 40px;
                    bottom: 2px;
                }

                .Drager-column-item-responsible {
                    color: #000;
                    padding: 10px 12px 10px;
                    background-color: rgb(255, 255, 255);
                    line-height: 1.5;
                    border-radius: 0 0 5px 5px;
                    font-size: 13px;
                    display: flex;
                    align-items: center;
                    font-weight: bold;

                    .Drager-column-item-responsible-selfie {
                        height: 30px;
                        width: 30px;
                        margin-right: 10px;
                        background: red;
                        border-radius: 100px;
                        background-size: cover;
                        background-position: center;
                    }
                }

                &:hover {
                    .Drager-column-item-content {
                        max-height: 700px;
                        -webkit-transition: all 800ms ease;

                        -moz-transition: all 800ms ease;
                        -o-transition: all 800ms ease;
                        transition: all 800ms ease;
                    }
                }

                .Drager-column-item-content {
                    max-height: 0px;
                    position: absolute;
                    border-radius: 5px;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
                    background-color: #fff;
                    top: 100%;
                    left: 0;
                    right: 0;
                    z-index: 10000;
                    ;
                    overflow: hidden;
                }
                    .Drager-column-item-description {
                        color: #000;
                        padding: 15px 20px 15px;
                        line-height: 1.5;
                        font-size: 13px;
                    }


                    .Drager-column-item-time {
                        display: none;
                        border-bottom: 1px solid rgba(170, 170, 170, 0.34);
                        border-top: 1px solid rgba(170, 170, 170, 0.264);
                        background: #eee;
                        position: relative;
                        padding: 10px 0;

                        .Drager-column-item-time-date {
                            color: #000;
                            font-size: 12px;
                            font-weight: normal;
                            padding: 5px 12px 0;
                            display: flex;
                            align-items: center;

                            b {
                                font-weight: bold;
                                margin-left: 3px;
                            }

                            i {
                                font-size: 20px;
                                margin-right: 7px;
                            }
                        }


                    }

                    .Drager-column-item-author {
                        color: #000;
                        background-color: rgb(226, 226, 226);
                        padding: 10px 13px 10px;
                        line-height: 1.5;
                        font-size: 13px;
                    }
               

            }
        }
    }


}