.Chart {
    position: relative;
    margin: 20;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding-left: 320px;
    padding-top: 20px;
    padding-right: 20px;
    min-height: 300px;
    padding-bottom: 20px;
    background-color: rgb(214, 214, 214);
    border-radius: 5px;
    margin-bottom: 20px;

}

.Chart .filter {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(73, 118, 255) !important;
    width: 300px;
    position: absolute;
    border-radius: 5px;
}

.Chart .total {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 200;
}

.Chart .result {
    font-size: 20px;
    color: #fff;
    padding: 0px;
}

.Chart .result span {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: block;
    padding: 10px 15px;
    font-size: 14px;
}

.Chart .result .total {
    font-size: 30px;
}

.Chart .range {
    margin-left: 30px;
    color: #fff;
    position: absolute;
}

.Chart .range span {
    padding: 5px 10px;
    display: inline-block;
    font-size: 20px;
    border-radius: 5px;
    margin-right: 10px;
    background: rgba(0, 0, 0, 0.1);
}


.Chart .period {
    position: absolute;
    border-radius: 5px;
    right: 20px;
    display: flex;
    gap: 5px;
    padding: 5px;
    top: 20px;
    background: rgba(0, 0, 0, 0.1);
}

.Chart .period span {
    padding: 7px 10px;
    background: rgba(0, 0, 0, 0.172);
    border-radius: 5px;
    color: #fff;
    display: inline-block;
}

.Chart .canvas {
    height: 260px;
}